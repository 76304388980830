const album = [
  "0T3A6771.jpg",
  "0T3A6218.jpg",
  "0T3A6447.jpg",
  "IMGL0541.jpg",
  "IMGL1074.jpg",
  "IMGL9936.jpg",
  "IMGL9577.jpg",
  "IMGL9949.jpg",
];

export default album;
