import React from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

type MyLightBoxProps = {
  open: boolean;
  onClose: () => void;
  slides: any[];
  index: number;
  render?: any;
};

const MyLightBox = ({
  open,
  onClose,
  slides,
  index,
  render,
}: MyLightBoxProps) => {
  return (
    <Lightbox
      open={open}
      close={onClose}
      index={index}
      slides={slides.map((photo: any) => {
        return { src: photo.src };
      })}
      plugins={[Zoom]}
      render={render}
    />
  );
};

export default MyLightBox;
