export const albumStudio = [
  "https://i.ibb.co/J5FNj8N/0T3A6005.jpg",
  "https://i.ibb.co/tcd6wDt/0T3A5932.jpg",
  "https://i.ibb.co/qrSbf9c/0T3A5998.jpg",
  "https://i.ibb.co/BqZshN2/0T3A6023.jpg",
  "https://i.ibb.co/CtZhRkD/0T3A6054.jpg",
  "https://i.ibb.co/48j4bb4/0T3A6074.jpg",
  "https://i.ibb.co/WBKnrt4/0T3A6131.jpg",
  "https://i.ibb.co/WtVJvP5/0T3A6146.jpg",
  "https://i.ibb.co/TbMvDMj/0T3A6218.jpg",
  "https://i.ibb.co/8D3MdJz/0T3A6242.jpg",
  "https://i.ibb.co/7XPwHNP/0T3A6250.jpg",
  "https://i.ibb.co/K9zdcWv/0T3A6276.jpg",
  "https://i.ibb.co/xGPtp2X/0T3A6287.jpg",
  "https://i.ibb.co/x8QXnnD/0T3A6291.jpg",
  "https://i.ibb.co/J5v8SdK/0T3A6318.jpg",
  "https://i.ibb.co/dkvDZmH/0T3A6342.jpg",
  "https://i.ibb.co/PZRLMjP/0T3A6356.jpg",
  "https://i.ibb.co/8dVBjgj/0T3A6377.jpg",
  "https://i.ibb.co/d25N6V5/0T3A6402.jpg",
  "https://i.ibb.co/WD4BvZv/0T3A6408.jpg",
  "https://i.ibb.co/B6fTsRL/0T3A6426.jpg",
  "https://i.ibb.co/CMz5dYD/0T3A6447.jpg",
  "https://i.ibb.co/54Nz7Kt/0T3A6458.jpg",
  "https://i.ibb.co/0qg4WX6/0T3A6473.jpg",
  "https://i.ibb.co/dppg549/0T3A6484.jpg",
  "https://i.ibb.co/hdhBCpg/0T3A6520.jpg",
  "https://i.ibb.co/cQ3VJ32/0T3A6521.jpg",
  "https://i.ibb.co/C7gWvqc/0T3A6572.jpg",
  "https://i.ibb.co/sj3q4XS/0T3A6594.jpg",
  "https://i.ibb.co/Tqt5Myw/0T3A6616.jpg",
  "https://i.ibb.co/4FQSSvn/0T3A6632.jpg",
  "https://i.ibb.co/2Y3NL4s/0T3A6648.jpg",
  "https://i.ibb.co/3dWH7ky/0T3A6653.jpg",
  "https://i.ibb.co/ZKkBVYS/0T3A6654.jpg",
  "https://i.ibb.co/Ss7RTcL/0T3A6679.jpg",
  "https://i.ibb.co/SsHbY2r/0T3A6733.jpg",
  "https://i.ibb.co/M6NZ4C5/0T3A6738.jpg",
  "https://i.ibb.co/0rGCFxf/0T3A6753.jpg",
  "https://i.ibb.co/qYcwp8R/0T3A6771.jpg",
  "https://i.ibb.co/VBt0f8Z/0T3A6788.jpg",
];

export const albumStudioThumbnail = [
  "https://i.ibb.co/HgVL5Qs/0T3A6788.jpg",
  "https://i.ibb.co/3mjHcb0/0T3A6771.jpg",
  "https://i.ibb.co/NKy9mML/0T3A6753.jpg",
  "https://i.ibb.co/3zh4qCY/0T3A6738.jpg",
  "https://i.ibb.co/JB8V6NQ/0T3A6733.jpg",
  "https://i.ibb.co/smJySCB/0T3A6679.jpg",
  "https://i.ibb.co/q9PNdWC/0T3A6654.jpg",
  "https://i.ibb.co/NCpzs76/0T3A6653.jpg",
  "https://i.ibb.co/vv4YrnQ/0T3A6648.jpg",
  "https://i.ibb.co/VLKCCnc/0T3A6632.jpg",
  "https://i.ibb.co/dBKDvH9/0T3A6594.jpg",
  "https://i.ibb.co/Pzg8Gkr/0T3A6616.jpg",
  "https://i.ibb.co/Fw2nJ9r/0T3A6572.jpg",
  "https://i.ibb.co/JHtgntr/0T3A6521.jpg",
  "https://i.ibb.co/B6dyw8f/0T3A6520.jpg",
  "https://i.ibb.co/3FFzCmX/0T3A6484.jpg",
  "https://i.ibb.co/cJGfpvZ/0T3A6473.jpg",
  "https://i.ibb.co/c3GpmYM/0T3A6458.jpg",
  "https://i.ibb.co/LJ8zwcH/0T3A6447.jpg",
  "https://i.ibb.co/gWrtgCy/0T3A6426.jpg",
  "https://i.ibb.co/Q83YvLv/0T3A6408.jpg",
  "https://i.ibb.co/qsrH9cr/0T3A6402.jpg",
  "https://i.ibb.co/tx5hbpb/0T3A6377.jpg",
  "https://i.ibb.co/Fb9rX3y/0T3A6356.jpg",
  "https://i.ibb.co/BTJBknY/0T3A6342.jpg",
  "https://i.ibb.co/pJfNkPx/0T3A6318.jpg",
  "https://i.ibb.co/CsFmjjz/0T3A6291.jpg",
  "https://i.ibb.co/ZKtpnfG/0T3A6287.jpg",
  "https://i.ibb.co/vJvpCkn/0T3A6276.jpg",
  "https://i.ibb.co/mGkLmFk/0T3A6250.jpg",
  "https://i.ibb.co/MDTnGb8/0T3A6242.jpg",
  "https://i.ibb.co/gPZjbZq/0T3A6218.jpg",
  "https://i.ibb.co/sHWY96R/0T3A6146.jpg",
  "https://i.ibb.co/7ryv34d/0T3A6131.jpg",
  "https://i.ibb.co/bsrN44N/0T3A6074.jpg",
  "https://i.ibb.co/W3wgmbj/0T3A6054.jpg",
  "https://i.ibb.co/8658ycg/0T3A6023.jpg",
  "https://i.ibb.co/qkFzYhz/0T3A6005.jpg",
  "https://i.ibb.co/ftcWVH5/0T3A5932.jpg",
  "https://i.ibb.co/XCQmgpK/0T3A5998.jpg",
];

export const albumDaLat = [
  "https://i.ibb.co/N94c1nM/IMGL0636.jpg",
  "https://i.ibb.co/6gktb8j/IMGL0119.jpg",
  "https://i.ibb.co/1dxVY8w/IMGL0152.jpg",
  "https://i.ibb.co/YLFNYfD/IMGL0331.jpg",
  "https://i.ibb.co/fMfcc7f/IMGL0232.jpg",
  "https://i.ibb.co/MD4dt36/IMGL0541.jpg",
  "https://i.ibb.co/cvwzHh1/IMGL0545.jpg",
  "https://i.ibb.co/7NNxk3V/IMGL0633.jpg",
  "https://i.ibb.co/34XmwkG/IMGL0724.jpg",
  "https://i.ibb.co/Xxchhg6/IMGL0743.jpg",
  "https://i.ibb.co/PxfyFsp/IMGL0785.jpg",
  "https://i.ibb.co/1961d0p/IMGL0822.jpg",
  "https://i.ibb.co/tLXTL3G/IMGL0970.jpg",
  "https://i.ibb.co/Gd4D8fG/IMGL0986.jpg",
  "https://i.ibb.co/k8fSyKY/IMGL1074.jpg",
  "https://i.ibb.co/MBrd7F4/IMGL1076.jpg",
  "https://i.ibb.co/w4Vcz65/IMGL1087.jpg",
  "https://i.ibb.co/F321hKv/IMGL1188.jpg",
  "https://i.ibb.co/fDkjsN0/IMGL1224.jpg",
  "https://i.ibb.co/9qpyJRH/IMGL1282.jpg",
  "https://i.ibb.co/brCw05x/IMGL9246.jpg",
  "https://i.ibb.co/ggYDKgg/IMGL9307.jpg",
  "https://i.ibb.co/5MXZhHm/IMGL9320.jpg",
  "https://i.ibb.co/wYjjfXn/IMGL9333.jpg",
  "https://i.ibb.co/bsPZxgm/IMGL9423.jpg",
  "https://i.ibb.co/T1R9r7m/IMGL9466.jpg",
  "https://i.ibb.co/1RpP8rL/IMGL9539.jpg",
  "https://i.ibb.co/DbbJcc3/IMGL9577.jpg",
  "https://i.ibb.co/vcNsj0d/IMGL9654.jpg",
  "https://i.ibb.co/R0M7yb5/IMGL9769.jpg",
  "https://i.ibb.co/LgW90cF/IMGL9936.jpg",
  "https://i.ibb.co/pd2SJw6/IMGL9949.jpg",
];

export const albumDaLatThumbnail = [
  "https://i.ibb.co/48QhsgH/IMGL0636.jpg",
  "https://i.ibb.co/72qpVQs/IMGL0119.jpg",
  "https://i.ibb.co/jWFK9g1/IMGL0152.jpg",
  "https://i.ibb.co/Nx544z5/IMGL0232.jpg",
  "https://i.ibb.co/rwRQT5b/IMGL0331.jpg",
  "https://i.ibb.co/TBSy7FL/IMGL0541.jpg",
  "https://i.ibb.co/59kqyh6/IMGL0545.jpg",
  "https://i.ibb.co/1JJYzCK/IMGL0633.jpg",
  "https://i.ibb.co/sbS9htk/IMGL0724.jpg",
  "https://i.ibb.co/8g1WWpG/IMGL0743.jpg",
  "https://i.ibb.co/TcFGYDX/IMGL0785.jpg",
  "https://i.ibb.co/Q6dLCJq/IMGL0822.jpg",
  "https://i.ibb.co/QDd3DXg/IMGL0970.jpg",
  "https://i.ibb.co/r6Swtkn/IMGL1074.jpg",
  "https://i.ibb.co/DrhdQ0y/IMGL1076.jpg",
  "https://i.ibb.co/PgKD6Cq/IMGL1087.jpg",
  "https://i.ibb.co/MSrHgsT/IMGL1188.jpg",
  "https://i.ibb.co/tLqV92C/IMGL1224.jpg",
  "https://i.ibb.co/gDrtGsZ/IMGL1282.jpg",
  "https://i.ibb.co/Yt1nCB6/IMGL9246.jpg",
  "https://i.ibb.co/LpKC7pp/IMGL9307.jpg",
  "https://i.ibb.co/Fxkp4T2/IMGL9320.jpg",
  "https://i.ibb.co/cxVVjHW/IMGL9333.jpg",
  "https://i.ibb.co/wSRXTNg/IMGL9423.jpg",
  "https://i.ibb.co/0KZHQ6h/IMGL9466.jpg",
  "https://i.ibb.co/8PRf6r0/IMGL9539.jpg",
  "https://i.ibb.co/ZJJj44y/IMGL9577.jpg",
  "https://i.ibb.co/VLFHqfC/IMGL9654.jpg",
  "https://i.ibb.co/Sn1JryL/IMGL9769.jpg",
  "https://i.ibb.co/7p5rSZf/IMGL9936.jpg",
  "https://i.ibb.co/dDBRGMs/IMGL9949.jpg",
];
