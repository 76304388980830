import React from "react";
import FullPageGallery from "../components/FullPageGallery/FullPageGallery";
import Menu from "../components/Menu Home/Menu";

type GalleryPageProps = {
  albumName: string;
};

const GalleryPage = ({ albumName }: GalleryPageProps) => {
  return (
    <>
      <Menu type="secondary" albumName={albumName} />
      <FullPageGallery albumName={albumName} />
    </>
  );
};

export default GalleryPage;
