import React, { useState } from "react";
import axios from "axios";

import "./RSVP.scss";

const RSVP = () => {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const [showModal, setShowModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = (e: any) => {
    e.preventDefault();

    setShowModal(true);
    setLoading(true);
    setError(false);
    setSuccess(false);

    axios
      .post(
        "https://sheet.best/api/sheets/ae9ed495-03d3-452b-a934-ac2180551cad",
        {
          Name: name,
          Message: message,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setName("");
          setMessage("");
          setSuccess(true);
        } else {
          setError(true);
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  const closeModal = (e: any) => {
    e.preventDefault();
    setShowModal(false);
  };

  return (
    <section className="rsvp">
      <div className="overlay"></div>
      <form onSubmit={onSubmit}>
        <p>
          Lời chúc quý giá của mọi người chắc chắn sẽ làm cho đám cưới của chúng
          con/ chúng em thêm phần hạnh phúc!
        </p>

        <fieldset>
          <input
            name="name"
            type="text"
            placeholder="Tên khách mời"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <textarea
            name="message"
            rows={8}
            required
            placeholder="Đôi lời gửi đến đôi vợ chồng trẻ (người non dạ)"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button type="submit">Gửi lời chúc</button>
        </fieldset>
        <div className="decoration">
          <img src={require("../../assets/flower.png")} alt="placeholder" />
        </div>
        {showModal && (
          <div className="modal">
            {loading && <div>Đang gửi lời chúc...</div>}
            {error && (
              <>
                <div>
                  Có lỗi xảy ra, xin quý khách mời vui lòng thử lại sau.
                </div>
                <button onClick={closeModal}>Đóng thông báo</button>
              </>
            )}
            {success && (
              <>
                <div>Gửi lời chúc thành công, cảm ơn quý khách mời!</div>
                <button onClick={closeModal}>Đóng thông báo</button>
              </>
            )}
          </div>
        )}
      </form>
    </section>
  );
};

export default RSVP;
