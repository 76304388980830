import React from "react";
import "./Event.scss";
import MyLightBox from "../../components/MyLightBox/MyLightBox";

const Event = () => {
  const [showLightBox, setShowLightBox] = React.useState(false);

  return (
    <section id="event" className="event">
      <div className="ceremony">
        <div className="name-container">
          <div className="icon"></div>
          <div className="name">
            Lễ
            <br />
            Hôn
            <br />
            Phối
          </div>
        </div>

        <div className="detail">
          <div className="date">Thứ 7, ngày 1 tháng 6 năm 2024</div>
          <div className="location">Nhà thờ Giáo xứ Thánh Tâm</div>
          <div className="address">
            43 Trần Hưng Đạo, P. Hiệp Phú, TP. Thủ Đức
          </div>
        </div>

        <div className="schedule">
          <span className="time">15:00</span>
          <span className="activity">Làm lễ</span>
        </div>
      </div>

      <div className="ceremony right">
        <div className="name-container">
          <div className="icon"></div>
          <div className="name">
            Tiệc
            <br />
            Cưới
          </div>
        </div>
        <div className="detail">
          <div className="date">Chủ nhật, ngày 2 tháng 6 năm 2024</div>
          <div className="location">Nhà hàng tiệc cưới Minh Phú (Sảnh A)</div>
          <div className="address">
            146 Lê Văn Việt, P. Tăng Nhơn Phú B, TP. Thủ Đức
          </div>
        </div>
        <div className="schedule">
          <div>
            <span className="time">11:00</span>
            <span className="activity">Đón khách</span>
          </div>
          <div>
            <span className="time">12:00</span>
            <span className="activity">Khai tiệc&nbsp;</span>
          </div>
        </div>
      </div>

      <div className="note">
        *Xem hướng đi đến bãi giữ xe nhà hàng tránh kẹt xe{" "}
        <span onClick={() => setShowLightBox(true)}>tại đây</span>
      </div>

      {showLightBox && (
        <div className="overlay" onClick={() => setShowLightBox(false)}>
          <div className="wrapper">
            <div className="map">
              <button>x</button>
            </div>
            <img
              className="background"
              src={require("../../assets/map.jpg")}
              alt="event"
            />
          </div>
        </div>
      )}

      <MyLightBox
        open={showLightBox}
        onClose={() => setShowLightBox(false)}
        index={0}
        slides={[{ src: require("../../assets/map.jpg") }]}
        render={{
          buttonPrev: () => null,
          buttonNext: () => null,
        }}
      />
    </section>
  );
};

export default Event;
