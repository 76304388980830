import React from "react";
import "./Welcome.scss";

const Welcome = () => {
  return (
    <section className="welcome">
      <div className="background-container">
        <img
          // className="small"
          src={require("../../assets/B1.png")}
          alt="decoration"
        />
        {/* <img
          className="large"
          src={require("../../assets/B1_large.png")}
          alt="decoration"
        /> */}
      </div>
      <p>
        Hai vợ chồng Thảo & Thông mến chào quý cô chú, anh chị em, bạn bè. Xin
        chân thành cảm ơn mọi người đã ghé thăm chiếc page nho nhỏ của chúng
        con/ chúng em. Thương chúc mọi người một ngày tốt lành ạ!
      </p>
      <div className="background-container right">
        <img
          // className="small"
          src={require("../../assets/B2.png")}
          alt="decoration"
        />
        {/* <img
          className="large"
          src={require("../../assets/B2_large.png")}
          alt="decoration"
        /> */}
      </div>
      <div className="overlay"></div>
    </section>
  );
};

export default Welcome;
