import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Carousel.scss";

import CountDown from "./CountDown/CountDown";

const images = [
  {
    original: "assets/A1.jpg",
  },
  {
    original: "assets/A2.jpg",
  },
  {
    original: "assets/A3.jpg",
  },
  {
    original: "assets/A4.jpg",
  },
  {
    original: "assets/A5.jpg",
  },
  {
    original: "assets/A6.jpg",
  },
];

function Carousel() {
  return (
    <div id="home" className="carousel-container">
      <div className="overlay"></div>

      <ImageGallery
        infinite={true}
        autoPlay={true}
        items={images}
        slideInterval={6500}
        slideDuration={1500}
        showNav={false}
        showBullets={true}
        showFullscreenButton={false}
        showPlayButton={false}
      />
      <div className="fixed-overlay">
        <div className="name-container">
          <div className="slogan">We are getting married!</div>
          <div className="name">Quốc Thông & Phương Thảo</div>
        </div>

        <CountDown />
      </div>
    </div>
  );
}

export default Carousel;
