import React, { useState, useEffect } from "react";

import "./CountDown.scss";

const eventDate = new Date("June 2, 2024 00:00:00");

const CountDown = () => {
  const [show, setShow] = useState(false);

  const now = Date.now();
  const [timeLeft, setTimeLeft] = useState(now);
  const daysLeft = Math.floor(timeLeft / (1000 * 3600 * 24));
  const hoursLeft = 24 - new Date(now).getHours();
  const minutesLeft = 60 - new Date(now).getMinutes();
  const secoundsLeft = 60 - new Date(now).getSeconds();

  useEffect(() => {
    if (!timeLeft) return;
    setTimeout(() => {
      setTimeLeft(eventDate.getTime() - Date.now());
    }, 1000);
  }, [timeLeft, now]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timer
  });

  const formatTime = (time: number) => {
    return time.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  };

  if (!show) {
    return null;
  }

  return (
    <div className="countdown-container">
      <div className="date">Ngày 02 tháng 06 năm 2024</div>
      <div className="countdown">
        <span className="timer">
          <div className="number">{formatTime(daysLeft)}</div>
          <div className="label">Ngày</div>
        </span>
        <span className="timer">
          <div className="number">{formatTime(hoursLeft)}</div>
          <div className="label">Giờ</div>
        </span>
        <span className="timer">
          <div className="number">{formatTime(minutesLeft)}</div>
          <div className="label">Phút</div>
        </span>
        <span className="timer">
          <div className="number">{formatTime(secoundsLeft)}</div>
          <div className="label">Giây</div>
        </span>
      </div>
    </div>
  );
};

export default CountDown;
