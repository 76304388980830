import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import HomePage from "./page/HomePage";
import GalleryPage from "./page/GalleryPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/gallery-studio"
          element={<GalleryPage albumName="studio" />}
        />
        <Route
          path="/gallery-dalat"
          element={<GalleryPage albumName="dalat" />}
        />
      </Routes>
    </Router>
  );
}

export default App;
