import React, { useEffect } from "react";
import { useState } from "react";
import NavigationBar from "./NavigationBar/NavigationBar";
import "./Menu.scss";

type MenuProps = {
  type?: string;
  albumName?: string;
};

const Menu = ({ type, albumName }: MenuProps) => {
  const [isActived, setIsActived] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const handleClick = () => {
    setIsActived(!isActived);
  };

  const onScroll = () => {
    if (window.scrollY > 100) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };

  useEffect(() => {
    if (type === "secondary") {
      setIsScrolling(true);
      return;
    }

    window.addEventListener("scroll", onScroll);
  }, [type]);

  return (
    <div className={`menu-home ${isScrolling ? "menu--scrolled" : ""}`}>
      <div className="menu-content">
        <div className="logo">
          <span>Thông</span>
          <span>&</span>
          <span>Thảo</span>
        </div>
        <div
          className={`toggle ${isActived ? "toggle--active" : ""}`}
          onClick={handleClick}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <NavigationBar
          type={type}
          isActived={isActived}
          albumName={albumName}
        />
      </div>
    </div>
  );
};

export default Menu;
