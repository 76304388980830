import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <section className="footer">
      <p>
        Hai vợ chồng Thảo & Thông xin chân thành cảm ơn mọi người rất rất rất
        nhiều!
        <br />
        Chúng con/ chúng em mong được gặp mọi người ở tiệc cưới ạ!
      </p>

      <div>
        ©2024 Design with ❤️ by <strong>Thong & Thao</strong>. Developed by{" "}
        <strong>Dat Truong</strong>.
      </div>
    </section>
  );
};

export default Footer;
