import React, { useState } from "react";

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "yet-another-react-lightbox/styles.css";
import "./FullPageGallery.scss";

import {
  albumStudio,
  albumStudioThumbnail,
  albumDaLat,
  albumDaLatThumbnail,
} from "../../assets/album";
import MyLightBox from "../MyLightBox/MyLightBox";

type FullPageGalleryProps = {
  albumName: string;
};

const FullPageGallery = ({ albumName }: FullPageGalleryProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showLightBox, setShowLightBox] = useState(false);

  const mapImageAndThumbnail = (album: string[], thumbnails: string[]) => {
    return album.map((image: string) => {
      const imageName = image.substring(image.lastIndexOf("/") + 1);
      const thumbnail =
        thumbnails.find((thumbnail: string) => thumbnail.includes(imageName)) ||
        image;

      return { src: image, thumbnail };
    });
  };

  const mappedAlbum =
    albumName === "studio"
      ? mapImageAndThumbnail(albumStudio, albumStudioThumbnail)
      : mapImageAndThumbnail(albumDaLat, albumDaLatThumbnail);

  const toggleLightBox = (image: any) => {
    const index = mappedAlbum.findIndex((ele) => ele.src === image.src);
    setCurrentIndex(index);
    setShowLightBox(!showLightBox);
  };
  console.log("albumName :", albumName);

  return (
    <div className="full-page-gallery" id="home">
      <div className={`heading ${albumName}`}>
        <div className="heading-overlay"></div>
        <div className="heading-title">
          {albumName === "studio" ? "Album Ảnh Studio" : "Album Ảnh Đà Lạt"}
        </div>
      </div>

      <section className="album-section">
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 4 }}>
          <Masonry className="masonry" columnsCount={4} gutter="10px">
            {mappedAlbum.map((image, index) => (
              <LazyLoadImage
                key={index}
                src={image.thumbnail}
                width={"auto"}
                height={"auto"}
                onClick={() => toggleLightBox(image)}
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </section>

      <MyLightBox
        open={showLightBox}
        onClose={() => setShowLightBox(false)}
        index={currentIndex}
        slides={mappedAlbum}
      />
    </div>
  );
};

export default FullPageGallery;
