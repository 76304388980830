import React from "react";
import "./Story.scss";

const Story = () => {
  return (
    <section id="story" className="story">
      <h1>Chuyện Tình Yêu</h1>

      <div className="story-container">
        <div className="milestone">
          <div className="icon ">
            <div className="img-wrapper">
              <img src={require("../../assets/D1.png")} alt="placeholder" />
            </div>
          </div>
          <div className="image">
            <img src={require("../../assets/D1.jpg")} alt="placeholder" />
          </div>
          <div className="detail">
            <div className="heading">Facebook Dating</div>
            <div className="time">
              <span>Ngày</span> 13/06/2022
            </div>
            <p className="text">
              Thông và Thảo quen biết nhau qua mục Hẹn hò của Facebook. Sau thời
              gian tìm hiểu thì cả hai thấy có rất nhiều điểm chung, cùng thích
              leo núi, thích vận động và thích xem phim hoạt hình Ghibli, v.v
              nên đôi bạn đã quyết định tìm hiểu nghiêm túc hơn.
            </p>
          </div>
        </div>
        <div className="milestone">
          <div className="image">
            <img src={require("../../assets/D2.jpg")} alt="placeholder" />
          </div>
          <div className="icon">
            <div className="img-wrapper">
              <img src={require("../../assets/D2.png")} alt="placeholder" />
            </div>
          </div>
          <div className="detail">
            <div className="heading">Chính thức hẹn hò</div>
            <div className="time">
              <span>Ngày</span> 13/08/2022
            </div>
            <p className="text">
              Anh ấy chuẩn bị sẵn hoa, bầu không khí vô cùng trang trọng. Anh ấy
              lấy hết sức bình sinh bày tỏ tình cảm của mình. Đột nhiên! "Anh
              chờ em 15 phút để em đi đánh răng nha". Anh ấy thẫn thờ ngồi chờ,
              may quá lát sau trở lại cô ấy đồng ý thật.
            </p>
          </div>
        </div>
        <div className="milestone">
          <div className="image">
            <img src={require("../../assets/D3.jpg")} alt="placeholder" />
          </div>
          <div className="icon">
            <div className="img-wrapper">
              <img src={require("../../assets/D3.png")} alt="placeholder" />
            </div>
          </div>
          <div className="detail">
            <div className="heading">Cầu hôn bất ngờ</div>
            <div className="time">
              <span>Ngày</span> 16/12/2023
            </div>
            <p className="text">
              Sau chuyến tiền trạm đầy drama như một bộ phim cổ trang lan man
              tại núi Chứa Chan, với sự trợ giúp của đoàn hộ nhẫn rất tận tâm,
              anh ấy đã cầu hôn thành công trước sự ngỡ ngàng của cô ấy. Trải
              qua biết bao khó khăn, cuối cùng, đi rồi cũng đến đích.
            </p>
          </div>
        </div>
        <div className="milestone">
          <div className="image">
            <img src={require("../../assets/D4.jpg")} alt="placeholder" />
          </div>
          <div className="icon bottom">
            <div className="img-wrapper">
              <img src={require("../../assets/D4.png")} alt="placeholder" />
            </div>
          </div>{" "}
          <div className="detail">
            <div className="heading">Về chung một nhà</div>
            <div className="time">
              <span>Ngày</span> 01/06/2024
            </div>
            <p className="text">
              Hai bạn trẻ quyết định cái kết của mối nhân duyên này sẽ có hoa
              thơm quả ngọt bằng một đám cưới nho nhỏ, tuy đơn sơ nhưng ấm cúng,
              thân mật giữa tiết trời nắng nhẹ của một buổi trưa mùa hạ tháng 6.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Story;
