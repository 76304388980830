import React from "react";
import Carousel from "../sections/Carousel/Carousel";
import Welcome from "../sections/Welcome/Welcome";
import Event from "../sections/Event/Event";
import Story from "../sections/Story/Story";
import Gallery from "../sections/Gallery/Gallery";
import RSVP from "../sections/RSVP/RSVP";
import Footer from "../sections/Footer/Footer";
import Menu from "../components/Menu Home/Menu";

const HomePage = () => {
  return (
    <div>
      <Menu type="" />
      <Carousel />
      <Welcome />
      <Event />
      <Story />
      <Gallery />
      <RSVP />
      <Footer />
    </div>
  );
};

export default HomePage;
