import React, { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

import MyLightBox from "../../components/MyLightBox/MyLightBox";
import Album from "./Album";
import { albumDaLat, albumStudio } from "../../assets/album";

import "./Gallery.scss";

const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showLightBox, setShowLightBox] = useState(false);

  const mapImageAndThumbnail = (album: any) => {
    return album.map((image: string) => {
      const imageName = image.substring(image.lastIndexOf("/") + 1);
      const src =
        albumDaLat.find((thumbnail: string) => thumbnail.includes(imageName)) ||
        albumStudio.find((thumbnail: string) => thumbnail.includes(imageName));

      return { src, thumbnail: image };
    });
  };

  const album = mapImageAndThumbnail(Album);

  const toggleLightBox = (index: number) => {
    setCurrentIndex(index);
    setShowLightBox(!showLightBox);
  };

  return (
    <section id="gallery" className="gallery">
      <h1>Gallery Ảnh</h1>

      <div className="masonry-wrapper">
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 4 }}>
          <Masonry className="masonry" columnsCount={4} gutter="10px">
            {album.map((photo: any, index: number) => (
              <LazyLoadImage
                key={index}
                src={require(`../../assets/gallery/${photo.thumbnail}`)}
                width={"auto"}
                height={"auto"}
                alt="Thong Thao Wedding"
                onClick={() => toggleLightBox(index)}
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>

      <MyLightBox
        open={showLightBox}
        onClose={() => setShowLightBox(false)}
        index={currentIndex}
        slides={album}
      />

      <div className="button-group">
        <Link to="/gallery-studio">
          <button className="btn">Xem thêm ảnh Studio</button>
        </Link>
        <Link to="/gallery-dalat">
          <button className="btn">Xem thêm ảnh Đà Lạt</button>
        </Link>
      </div>
    </section>
  );
};

export default Gallery;
