/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./NavigationBar.scss";
import { Link } from "react-router-dom";

const NavigationBar = ({ type, isActived, albumName }) => {
  if (type === "secondary") {
    return (
      <nav className={`nav-home ${isActived ? "nav--active" : ""}`}>
        {albumName === "dalat" ? (
          <a href="/gallery-studio">Album studio</a>
        ) : (
          <a href="/gallery-dalat">Album Đà Lạt</a>
        )}
        <Link to="/">Về trang chủ</Link>
      </nav>
    );
  }

  return (
    <nav className={`nav-home ${isActived ? "nav--active" : ""}`}>
      <a href="#home">Đầu Trang</a>
      <a href="#event">Timeline</a>
      <a href="#gallery">Gallery Ảnh</a>
    </nav>
  );
};

export default NavigationBar;
